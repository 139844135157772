<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2022. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->
<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <div v-on="on">
        <v-btn
          id="iiotNodeInformationTypeIcon"
          :class="`${tableItem.type}-btn-color`"
          class="ml-1 mb-1 iconSize"
          :disabled="true"
          fab
          dark
          small
        >
          <v-img v-if="tableItem.type === 'docker-compose'" :src="`/img/${tableItem.type}.svg`" />
          <v-icon v-else x-small>
            {{ `$vuetify.icons.${tableItem.type}` }}
          </v-icon>
        </v-btn>
      </div>
    </template>
    <span>{{ tableItem.type }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style scoped>
.iconSize {
  width: 20px;
  height: 20px;
}
</style>
